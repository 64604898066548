let pubs = [
    {
        "title": "PRP: Propagating Universal Perturbations to Attack Large Language Model Guard-Rails",
        "authors": "Neal Mangaokar (co-lead), Ashish Hooda (co-lead), Jihye Choi, Shreyas Chandrashekaran, Kassem Fawaz, Somesh Jha, and Atul Prakash",
        "conference_info": "ACL 2024, Bangkok, Thailand, August 2024",
        "filename": "https://aclanthology.org/2024.acl-long.591.pdf",
        "code": "https://github.com/AshishHoodaIITD/prp-llm-guard-rail-attack"
    },
    {
        "title": "D4: Detection of Adversarial Diffusion Deepfakes Using Disjoint Ensembles",
        "authors": "Ashish Hooda (co-lead), Neal Mangaokar (co-lead), Ryan Feng, Kassem Fawaz, Somesh Jha, and Atul Prakash",
        "conference_info": "IEEE/CVF WACV, Waikoloa, Hawaii, January 2024",
        "filename": "https://openaccess.thecvf.com/content/WACV2024/papers/Hooda_D4_Detection_of_Adversarial_Diffusion_Deepfakes_Using_Disjoint_Ensembles_WACV_2024_paper.pdf",
        "code": "https://github.com/nmangaokar/wacv_24_d4"
    },
    {
        "title": "Stateful Defenses for Machine Learning Models Are Not Yet Secure Against Black-box Attacks",
        "authors": "Ryan Feng (co-lead), Ashish Hooda (co-lead), Neal Mangaokar (co-lead), Kassem Fawaz, Somesh Jha, and Atul Prakash",
        "conference_info": "ACM CCS, Copenhagen, Denmark, November 2023",
        "filename": "https://arxiv.org/pdf/2303.06280.pdf",
        "code": "https://github.com/nmangaokar/ccs_23_oars_stateful_attacks/tree/main"
    },
    {
        "title": "Theoretically Principled Trade-off for Stateful Defenses against Query-Based Black-Box Attacks",
        "authors": "Ashish Hooda (co-lead), Neal Mangaokar (co-lead), Ryan Feng, Kassem Fawaz, Somesh Jha, and Atul Prakash",
        "conference_info": "AdvML Frontiers Workshop at ICML, Hawaii, 2023",
        "filename": "https://openreview.net/forum?id=ne7bt3IlFl",
    },
    {
        "title": "GRAPHITE: Generating Automatic Physical Examples for Machine-Learning Attacks on Computer Vision Systems",
        "authors": "Ryan Feng, Neal Mangaokar, Jiefeng Chen, Earlence Fernandes, Somesh Jha, and Atul Prakash",
        "conference_info": "IEEE EuroS&P, Genova, Italy, June 2022",
	    "filename": "https://arxiv.org/pdf/2002.07088.pdf",
        "code": "https://github.com/ryan-feng/GRAPHITE",
        "presentation_link": require("./docs/EuroS&P Presentation.pdf"),
    },
    {
        "title": "Dispelling Misconceptions and Characterizing the Failings of Deepfake Detection",
        "authors": "Neal Mangaokar and Atul Prakash",
        "conference_info": "IEEE S&P Magazine, October 2021",
        "filename": "https://www.computer.org/csdl/magazine/sp/5555/01/09576757/1xIKuV3DPIQ",
    },
    {
        "title": "Deepfake Videos in the Wild: Analysis and Detection",
        "authors": "Jiameng Pu (co-lead), Neal Mangaokar (co-lead), Lauren Kelly, Parantapa Bhattacharya, Kavya Sundaram, Mobin Javed, Bolun Wang, and Bimal Viswanath",
        "conference_info": "ACM WWW, Ljubljana, Slovenia, August 2021",
        "filename": "https://arxiv.org/pdf/2103.04263.pdf",
        "code": "https://github.com/jmpu/webconf21-deepfakes-in-the-wild",
        "presentation_link": "https://drive.google.com/file/d/1100nIF9nXlyKZpL1H_s9Gi5knZLOeKkT/view"                                                                                             
    },
    {
        "title": "T-Miner: A Generative Approach to Defend Against Trojan Attacks on DNN-based Text Classification",
        "authors": "Ahmadreza Azizi, Ibrahim Asadullah Tahmid, Asim Waheed, Neal Mangaokar, Jiameng Pu, Mobin Javed, Chandan K. Reddy, and Bimal Viswanath",
        "conference_info": "USENIX Security Symposium, Vancouver, CA, August 2021",
        "filename": "https://arxiv.org/pdf/2103.04264.pdf",
        "code": "https://github.com/reza321/T-Miner",
        "presentation_link": "https://www.usenix.org/conference/usenixsecurity21/presentation/azizi"                                                                                             
    },
    {
        "title": "NoiseScope: Detecting Deepfake Images in a Blind Setting",
        "authors": "Jiameng Pu, Neal Mangaokar, Bolun Wang, Chandan K. Reddy, and Bimal Viswanath",
        "conference_info": "ACM ACSAC, Austin, USA, December 2020",
        "filename": require("./pubs/noisescope.pdf"),
        "code": "https://github.com/jmpu/NoiseScope",
        "presentation_link": "https://drive.google.com/file/d/1FwCCmwf-5PRwwBx2PMjYRSZM5C2SWzYT/view"

    },
    {
        "title": "Jekyll: Attacking Medical Image Diagnostics using Deep Generative Models",
        "authors": "Neal Mangaokar, Jiameng Pu, Parantapa Bhattacharya, Chandan K. Reddy, and Bimal Viswanath",
        "conference_info": "IEEE EuroS&P, Genova, Italy, September 2020",
        "filename": require("./pubs/jekyll.pdf"),
        "presentation_link": "https://drive.google.com/file/d/1IvKrRn7uB9t-psLVRbQcE8qDvMEP64yH/view?usp=sharing"
    }
];

export default pubs;
